import React from 'react'

import SEO from '../../components/seo'

import Layout from '../../components/layout'

import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import Testimonial from '../../components/Testimonial/Testimonial'
import TextBlock from '../../components/TextBlock/TextBlock'
import Slider from '../../components/Slider/Slider'
import Goals from '../../components/Goals/Goals'
import Outcomes from '../../components/Outcomes/Outcomes'
import BottomNav from '../../components/BottomNav/BottomNav'

import Logo from '../../images/radium-logo.png'
import { GoogleAmpIcon } from '../../components/icons/GoogleAmpIcon'
import { JavascriptIcon } from '../../components/icons/JavascriptIcon'
import { SketchIcon } from '../../components/icons/SketchIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { CssIcon } from '../../components/icons/CssIcon'

const RadiumPage = () => {
  const services = [
    {
      id: 1,
      text: 'Providing development resources',
    },
    {
      id: 2,
      text: 'Project management',
    },
  ]

  const goals = [
    {
      id: 11,
      text: "Improve relationship with Radium's clients",
    },
    {
      id: 22,
      text:
        'Provide User Interface (UI) projects development based on custom design',
    },
    {
      id: 33,
      text: 'On-going support and collaboration',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: '36',
      subtitle: 'months of ongoing partnership',
    },
    {
      id: 222,
      title: '3',
      subtitle: 'large projects delivery on-time',
    },
    {
      id: 333,
      title: '80,000',
      subtitle: 'active users',
    },
    {
      id: 444,
      title: '36',
      subtitle: 'multiple sites in EMEA',
    },
  ]

  const slides = [
    {
      id: 1,
      img:
        'https://cdn.emersoft.co/emersoft-website/radium/radium-slider-image3.jpg',
      alt: 'Radium Team',
    },
    {
      id: 2,
      img:
        'https://cdn.emersoft.co/emersoft-website/radium/radium-slider-image2.jpg',
      alt: 'Radium office desk',
    },
    {
      id: 3,
      img:
        'https://cdn.emersoft.co/emersoft-website/radium/radium-slider-image4.jpg',
      alt: 'Radium team out',
    },
    {
      id: 4,
      img:
        'https://cdn.emersoft.co/emersoft-website/radium/radium-slider-image5.jpg',
      alt: 'Radium team office',
    },
  ]

  const techs = [
    {
      id: 1111,
      icon: JavascriptIcon(),
    },
    {
      id: 2222,
      icon: GoogleAmpIcon(),
    },
    {
      id: 3333,
      icon: SketchIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
    {
      id: 5555,
      icon: CssIcon(),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Radium"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
        ]}
      />

      <HeaderImg
        header="header-img--radium"
        img={Logo}
        wrapper="header-img__img-wrapper--radium"
      />
      <ProjectOverview
        title="Radium"
        link="https://radiumagency.com/"
        category='Digital Agency'
        services={services}
        technologies={techs}
        subtitle="Ongoing Partner Collaboration"
        paragraph1="Radium is a digital agency based in London. They provide all-around customer experience for startups and brands."
        paragraph2="They specialize in Strategy & Data Analysis, user experience and design through data insights and information gathered from research."
      />
      <Testimonial
        paragraph="I am endlessly greatful for the support these guys provide to me.
          We've been working for 3 years now and I am absolutely looking
          forward to continue our work together."
        author="Faisal Hussain"
        authorRole="CEO Radium"
      />
      <TextBlock
        heading="Brief"
        paragraph1="The client had some large, over six months projects they were not able to finish by themselves. They hired us to help them out with on-time delivery. One of the projects was a dedicated platform for photographers from Europe, Middle East and Africa (EMEA) for professionals."
        paragraph2="The other project were editorial pages for professional customers. In the most recent project Radium needed help with redevelopment of the legacy components to fit into their client’s new CMS system."
      />
      <Slider slides={slides} />
      <Goals goalsList={goals} />
      <TextBlock
        heading="Our Process"
        paragraph1="We joined Radium agency to support their front-end development
         team and on their behalf work with their multinational client. After
          we established the objectives and details of our co-operation we
          agreed upon follow-up agenda. That included meeting the client's
          staff and stakeholders on a regular basis to plan and showcase the
          project’s progress."
        paragraph2="We ensured a proper planning and management of the project
        by using Jira software and Agile methodology. Our organized and quick approach resulted in on-time delivery. The client also got an after-delivery support from us to ensure a proper workflow."
      />
      <Outcomes outcomes={outcomes} fourItems />
      <BottomNav
        heading="See More"
        firstLink="/our-work/unitruck"
        firstHeading="Unitruck"
        secondLink="/our-work/abbacruises"
        secondHeading="Abbacruises"
      />
    </Layout>
  )
}

export default RadiumPage
