import React from 'react'

export const GoogleAmpIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>Google AMP</title>
      <g fill="none" fillRule="evenodd">
        <g id="amp" fill="currentColor">
          <path d="M26.671 17.87L18.34 31.605h-1.51l1.493-8.949-4.622.007h-.066a.75.75 0 0 1-.752-.746c0-.177.166-.477.166-.477l8.304-13.718 1.535.007-1.53 8.962 4.646-.005.073-.001a.75.75 0 0 1 .754.747.719.719 0 0 1-.16.439zM19.861 0C8.891 0 0 8.808 0 19.672c0 10.866 8.892 19.672 19.861 19.672 10.97 0 19.862-8.807 19.862-19.672C39.723 8.808 30.831 0 19.861 0z" />
        </g>
      </g>
    </svg>
  )
}
